import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Typography, CircularProgress } from '@mui/material';
import { getCompanyDetail } from '../../service/company';

function MyCompany() {
    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = JSON.parse(localStorage.getItem('user')) ;

    const fetchCompanyData = async () => {
        try {
        const data = await getCompanyDetail(user.company);
        setCompanyData(data);
        setLoading(false);
        } catch (error) {
        console.error('Error fetching company data:', error);
        setLoading(false);
        }
    };

    useEffect(() => {
        fetchCompanyData();
    }, []);

    const handleUpdate = async () => {
        setLoading(true);
        await fetchCompanyData();
    };

    return (
    <div style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
            Información de la Empresa
        </Typography>
        {loading ? (
            <CircularProgress />
        ) : companyData ? (
            <div>
            <Typography variant="h6">{companyData.name}</Typography>
            <Typography variant="body1">Total SUNAT: {companyData.total_sunat}</Typography>
            <Typography variant="body1">
                Fecha de finalización SUNAT: {companyData.date_finish_sunat}
            </Typography>
            <Button variant="contained" color="primary" onClick={handleUpdate} style={{ marginTop: '10px' }}>
                Actualizar
            </Button>
            </div>
        ) : (
            <Typography variant="body1">No se encontraron datos de la empresa.</Typography>
        )}
        </div>
    );
}

export default MyCompany;
