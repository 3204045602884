import axiosInstance from "../axiosInstance";

export const getCompany = async () => {
    try {
        const response = await axiosInstance.get(`company/`);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const getCompanyDetail = async (id) => {
    try {
        const response = await axiosInstance.get(`company/${id}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
