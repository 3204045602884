import React, {   useState } from "react";
import {   useLocation } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import PublicRoutes from "./PublicRoutes";
 
import MyNavbar from "../components/navbar/MyNavbar";
import ResponsiveAppBar from "../components/navbar/MyNavbar";

const MisRutas = () => {

  const location = useLocation();
  const noNavbarSidebarRoutes = ["/login",];
  const noNavbarSidebar = noNavbarSidebarRoutes.includes(location.pathname);
  return (
    <>
        {!noNavbarSidebar && (
            <>

            <ResponsiveAppBar/>
            <ProtectedRoutes />

            </>
        )}
        {noNavbarSidebar &&  
          <>
          <PublicRoutes />


          </>
        
        }
    </>
    );
};

export default MisRutas;
