import axiosInstance from "../axiosInstance";

export const getUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`https://api.example.com/users/${userId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (payload) => {
    try {
        const response = await axiosInstance.post(`user/login/`, payload);
        return response.data;
    } catch (error) {
      throw error;
    }
};

export const logout = async (navigate) => { // Pasar navigate como argumento
    try {
        const response = await axiosInstance.post(`user/logout/`);
        return response.data;
    } catch (error) {
        throw error;
    }
    finally{
        localStorage.clear();
        navigate('/login'); 
    }
};
