import { Routes, Route } from "react-router-dom";

import SignInSide from "../pages/login";
import NotFound from "../pages/notFound";

const PublicRoutes = () => (
  <Routes>
          <Route path="/login" element={<SignInSide />} />
          <Route path="*" element={<NotFound />} />
  </Routes>
);

export default PublicRoutes;
