import axios from "axios";
import { API_URL } from "../helpers/config";
import isAuthenticated from "../helpers/autenticate";
const axiosInstance = axios.create({baseURL:API_URL});
const token = JSON.parse(localStorage.getItem('token')) ;

axiosInstance.interceptors.request.use(
    (request) =>{

        if(request.url==='user/login/'){
            return request;
        }
        else if(request.url==='reporteTarjeta'){
            request.responseType = 'blob'
    
        }
        console.log(token.token)
        request.headers['Authorization'] = `Token ${token.token}` 
        return request;
    },
    (err) =>err
   
   );
axiosInstance.interceptors.response.use(
    (response)=>response,
    (err)=>{
        console.log("eer",err.response)
        if (err.response && err.response.data.detail === 'Token inválido.') {
            localStorage.removeItem('token');
            return Promise.reject(err.response.data);
            
        }
        else if (err.response && err.response.status === 500) {
            return Promise.reject({
                status: 500,
                timestamp: new Date(),
                message: "Error interno del servidor",
            });
        }
        else if(!err.response){
            return Promise.reject({
                status:404,
                timestamp:new Date(),
                message:"Se ha producido un error no contemplado",

            });
        }
        else if(err.response.data){
            return Promise.reject(err.response.data);
        }
        else{
            return Promise.reject({
                status:404,
                timestamp:new Date(),
                message:"Se ha producido un error no contemplado",
            });

        }
        
    }
)
export default axiosInstance;