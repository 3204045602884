import { Navigate, useLocation } from "react-router-dom";
import isAuthenticated from "../helpers/autenticate";

const NO_NAVBAR_SIDEBAR_ROUTES = ["/login"];

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const logueado = isAuthenticated.userSession()
  console.log(logueado,location.pathname)
  if (!logueado && !NO_NAVBAR_SIDEBAR_ROUTES.includes(location.pathname)) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
