import React from 'react';
import MisRutas from './routes';
import { BrowserRouter as Router } from "react-router-dom";

function App() {
    return (
      <Router>
        <MisRutas></MisRutas>
      </Router>
    ) 
  }
  
export default App;
