let token;
try {
    token = JSON.parse(localStorage.getItem('token'));
} catch (error) {
    console.error('Error al analizar el token:', error);
    token = null;
}

let logueado = false;
let AuthStr = null;

if (token) {
    const date_today = new Date();
    const date_expiry = new Date(token.expiry);

    if (date_expiry > date_today) {
        AuthStr = `Token ${token.token}`;
        logueado = true;
    } else {
        localStorage.removeItem('token');
    }
} else {
    console.log("No se encontró un token en el almacenamiento local");
}

class Utilities { 
    userSession() {        
        return logueado;
    }
    getToken() {        
        return AuthStr;
    }
}

const isAuthenticated = new Utilities();
export default isAuthenticated;
