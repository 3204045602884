import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/dashboard/Dashboard";
import PrivateRoute from "./PrivatedRoutes";
const ProtectedRoutes = () => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const isSuperuser = user ? user.is_superuser : false;
    return (
        <PrivateRoute>
            <Routes>
                {!isSuperuser && (
                    <>
                    <Route path="/" element={<Dashboard />} />
                    </>
                )  
                }
                {isSuperuser && (
                    <>
                        <Route path="/" element={<Dashboard />} />
                    </>
                )}
            </Routes>
        </PrivateRoute>
    );
}

export default ProtectedRoutes;
